import "./Work.scss";

import { workIco1, workIco2, workIco3, workIco4 } from "../../../img/_index";

import { WorkBox } from "../../../components/_index";
import { useState } from "react";

const box1 = `Patrząc \nw przyszłość`;
const box2 = "Łamiąc \nstatus quo";
const box3 = "Pixel \nPerfect";
const box4 = "Nie bojąc \nsię wyzwań";
const content1 =
  "Inspiracji szukamy spoglądając na to, jak zmienia się świat. Zawsze jesteśmy gotowi na zmiany, które nadchodzą. Razem z naszymi klientami chcemy nie tylko uczestniczyć w tych zmianach, ale też wytyczać ich kierunek.";
const content2 =
  "Komfortowo jest korzystać z dobrze znanych rozwiązań i przyzwyczajać się do ich niedogodności. Dopiero kiedy wyjdziemy poza znane schematy, możemy tworzyć innowację, usprawniać codzienność i zmieniać otaczający nas świat.";
const content3 =
  "Czujemy się rzemieślnikami aplikacji. Każdy detal ma dla nas znaczenie. To, co widzi i doświadcza użytkownik, zdecyduje o jego ostatecznym wrażeniu o Twojej marce, dlatego projektujemy i kodzimy z dokładnością do jednego piksela.";
const content4 =
  "Nigdy nie boimy się sprostać ciężkim wymaganiom biznesu i użytkowników. Zadania, które mrożą krew w żyłach innym software house’om, nas przyprawiają o uśmiech i iskierkę w oku. Szalone projekty to nasza specjalność.";

const Work = () => {
  const [hover1, setHover1] = useState(true);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const hoverHandler1 = () => {
    setHover1(true);
    setHover2(false);
    setHover3(false);
    setHover4(false);
  };
  const hoverHandler2 = () => {
    setHover2(true);
    setHover1(false);
    setHover3(false);
    setHover4(false);
  };
  const hoverHandler3 = () => {
    setHover3(true);
    setHover1(false);
    setHover2(false);
    setHover4(false);
  };
  const hoverHandler4 = () => {
    setHover4(true);
    setHover1(false);
    setHover2(false);
    setHover3(false);
  };
  return (
    <section id="work">
      <div className="section-background">
        <div className="wrapper">
          <div className="title-container flex">
            <h1 className="">Jak pracujemy?</h1>
          </div>
          <div className="box-container">
            <WorkBox
              onMouseEnter={() => hoverHandler1()}
              hover={hover1 ? "hover" : ""}
              title={box1}
              content={content1}
              icon={workIco1}
            />
            <WorkBox
              onMouseEnter={() => hoverHandler2()}
              hover={hover2 ? "hover" : ""}
              title={box2}
              content={content2}
              icon={workIco2}
            />
            <WorkBox
              onMouseEnter={() => hoverHandler3()}
              hover={hover3 ? "hover" : ""}
              title={box3}
              content={content3}
              icon={workIco3}
            />
            <WorkBox
              onMouseEnter={() => hoverHandler4()}
              hover={hover4 ? "hover" : ""}
              title={box4}
              content={content4}
              icon={workIco4}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
