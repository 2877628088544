const IconPhone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.083"
      height="24.103"
      viewBox="0 0 24.083 24.103"
    >
      <path
        id="telephone_1_"
        data-name="telephone (1)"
        d="M15.781,24.1C6.124,24.031-5.19,9.96,3.077,1.685c.294-.3.6-.586.924-.854a3.51,3.51,0,0,1,4.743.2l.012.012L10.349,2.7A3.452,3.452,0,0,1,9.215,8.308a7.6,7.6,0,0,0-1.258.621C7.08,9.822,6.522,11.274,9.8,14.56c1.064,1.066,2.641,2.484,4.037,2.484a2.229,2.229,0,0,0,1.588-.732,7.314,7.314,0,0,0,.582-1.2,3.445,3.445,0,0,1,5.611-1.156l1.636,1.583.012.011a3.517,3.517,0,0,1,.21,4.736c-.25.3-.517.592-.793.869a9.551,9.551,0,0,1-6.9,2.954ZM6.267,1.886a1.639,1.639,0,0,0-1.056.388c-.274.23-.54.476-.792.732C.012,7.482,2.434,13.6,6.669,17.784c4.2,4.175,10.28,6.458,14.682,2.037.237-.237.466-.488.68-.745a1.625,1.625,0,0,0-.092-2.2L20.3,15.3l-.012-.011a1.55,1.55,0,0,0-2.537.521,7.255,7.255,0,0,1-.969,1.806,4.087,4.087,0,0,1-2.948,1.312c-2.072,0-3.958-1.623-5.369-3.036-2.141-2.054-4.616-5.557-1.822-8.315A7.476,7.476,0,0,1,8.5,6.565a1.557,1.557,0,0,0,.506-2.541L9,4.012,7.407,2.358a1.613,1.613,0,0,0-1.14-.472Zm17.088,9.368a.941.941,0,0,1-.936-.857A9.4,9.4,0,0,0,13.9,1.88.941.941,0,0,1,14.07,0,11.359,11.359,0,0,1,24.294,10.228a.941.941,0,0,1-.853,1.022Q23.4,11.254,23.355,11.254Zm-7.334,0a.941.941,0,0,0,.6-1.191A3.808,3.808,0,0,0,14.3,7.684a.941.941,0,0,0-.627,1.775,1.913,1.913,0,0,1,1.159,1.2.94.94,0,0,0,1.191.6Zm3.7-.008a.941.941,0,0,0,.8-1.065,7.583,7.583,0,0,0-6.4-6.4.941.941,0,0,0-.267,1.864,5.691,5.691,0,0,1,4.8,4.8.941.941,0,0,0,1.065.8Z"
        transform="translate(-0.214 -0.001)"
      />
    </svg>
  );
};

export default IconPhone;
