import "./Form.scss";

import { Button, CheckBox, Chip, Input, TextArea } from "../../_index";

import { Link } from "react-router-dom";
import { send } from "emailjs-com";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { validationRules } from "../../../helpers/validationRules";

const Form = ({ setRegards }) => {
  const [rodo, setRodo] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({ mode: "all" });

  let [topics, setTopics] = useState([
    {
      id: "topic-1",
      topic: "Aplikacja webowa",
      active: false,
    },
    {
      id: "topic-2",
      topic: "Strona www",
      active: false,
    },
    {
      id: "topic-3",
      topic: "Aplikacja mobilna",
      active: false,
    },
    {
      id: "topic-4",
      topic: "Product design",
      active: false,
    },
    {
      id: "topic-5",
      topic: "UX/UI",
      active: false,
    },
    {
      id: "topic-6",
      topic: "Audyt UX",
      active: false,
    },
    {
      id: "topic-7",
      topic: "Audyt IT",
      active: false,
    },
    {
      id: "topic-8",
      topic: "Automatyzacja procesów",
      active: false,
    },
    {
      id: "topic-9",
      topic: "MVP",
      active: false,
    },
    {
      id: "topic-10",
      topic: "Design Sprint",
      active: false,
    },
    {
      id: "topic-11",
      topic: "Inne",
      active: false,
    },
  ]);

  const handleClick = (topic) => {
    let index = topics.indexOf(topic);
    let tmpTopic = topic;
    let tmpTopics = topics.slice();

    tmpTopic.active = !tmpTopic.active;
    tmpTopics[index] = tmpTopic;
    setTopics(tmpTopics);
  };

  const onSubmit = (data) => {
    let subject;
    let messageTopics = topics.filter((topic) => topic.active && topic);
    messageTopics = messageTopics.map((topic) => topic.topic);
    if (messageTopics.length > 0) {
      subject = messageTopics.join(" - ");
    }
    send(
      "service_79gvmij",
      "template_jia54re",
      { ...data, subject: subject },
      "user_dorYlghKgM5moaiQhUDcF"
    ).then(
      () => {
        setRegards(true);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div id="form" className={setRegards ? "" : "fadeOut"}>
      <div className="box flex column">
        <h1 className="small black upper narrow">Let’s get it done</h1>
        <p className="">powiedz nam czego potrzebujesz</p>
        <form
          action=""
          className="flex column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="chips flex">
            {topics.map((topic) => {
              return (
                <Chip
                  key={topic.id}
                  onClick={() => handleClick(topic)}
                  active={topic.active}
                >
                  {topic.topic}
                </Chip>
              );
            })}
          </div>
          <div className="inputs flex">
            <Input
              placeholder="Imię i Nazwisko"
              name="name"
              register={register}
              error={errors.name}
              validationRules={validationRules.name}
            />
            <Input
              placeholder="Nazwa firmy"
              name="company"
              register={register}
              error={errors.company}
              validationRules={validationRules.company}
            />
            <Input
              placeholder="Email"
              name="mail"
              register={register}
              error={errors.mail}
              validationRules={validationRules.mail}
            />
            <Input
              placeholder="Nr telefonu"
              name="phone"
              register={register}
              error={errors.phone}
              validationRules={validationRules.phone}
            />
            <TextArea
              placeholder="Wiadomość"
              name="message"
              id="messageBody"
              register={register}
              error={errors.message}
            ></TextArea>
          </div>
          <div className="checkbox-container">
            <div
              className={rodo ? "rodo-popup flex" : "rodo-popup flex hidden"}
            >
              <p className="vsmall reverse">
                Wyrażam zgodę na otrzymywanie od Get **IT Done treści
                marketingowych (informacji handlowych) za pośrednictwem
                newslettera, w tym informacji o promocjach i ofertach
                specjalnych dotyczących Get **IT Done.
                <br />
                <br /> Administratorem Twoich danych osobowych jest firma PIOTR
                JUCHNIEWICZ (Biedaszki 20, 11-400, Biedaszki) Dane będą
                przetwarzane w celu wysyłki newslettera oraz ofert handlowych na
                zasadach określonych w{" "}
                <Link to="/regulations/statute" className="vsmall reverse">
                  regulaminie
                </Link>{" "}
                oraz{" "}
                <Link to="/regulations/policy" className="vsmall reverse">
                  polityce prywatności
                </Link>{" "}
                itdone.pl. Podanie danych jest dobrowolne, ale niezbędne do
                otrzymania newslettera i ofert handlowych. Osobie, której dane
                dotyczą, przysługuje prawo dostępu do treści swoich danych oraz
                ich poprawienia.
              </p>
              <div className="close-btn" onClick={() => setRodo(false)}></div>
            </div>
            <CheckBox name="rodo" register={register} />
            <p className="vsmall">
              Zapoznałem się z{" "}
              <span className="vsmall" onClick={() => setRodo(!rodo)}>
                informacją o administratorze i przetwarzaniu danych
              </span>
            </p>
          </div>
          <div className="btn-container">
            <Button
              className="reverse"
              onClick={handleSubmit(onSubmit)}
              disabled={!watch("rodo") && true}
            >
              Wyślij
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
