import "./AboutUs.scss";

import {
  IconFb,
  IconInstagram,
  IconLinkedIn,
  cosmonautGID1,
} from "../../../img/_index";

const AboutUs = () => {
  return (
    <section id="about">
      <div className="section-background">
        <div className="wrapper">
          <main>
            <h1 className="small black">GET YOUR **IT TOGETHER</h1>
            <div>
              <h3 className="">
                Rozumiemy zawiłości nowoczesnych rynków i panujące na nich
                trendy. Pomożemy Ci rozkochać użytkowników w Twoim produkcie.
              </h3>
              <p className="">
                Pomagamy wizjonerom przekształcać ich pomysły w podbijające
                rynek apki. Sprawiamy, że niemożliwe staje się możliwe dzięki
                kreatywnemu podejściu i nowoczesnym technologiom. Będziemy Twoim
                partnerem, zrozumiemy Twój biznes i stworzymy dla niego
                rozwiązanie szyte na miarę.
              </p>
            </div>
            <div className="social">
              <div className="social__container flex">
                <div className="social__container__links flex">
                  <a
                    href="https://www.facebook.com/itdonepl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconFb />
                  </a>
                  <a
                    href="https://www.instagram.com/itdonepl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/itdonepl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconLinkedIn />
                  </a>
                </div>
                <span className="">@itdone.pl</span>
              </div>
            </div>
          </main>
          <aside>
            <div className="img-container">
              <img src={cosmonautGID1} alt="" />
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
