import "./BannerTech.scss";

import { Button } from "../../../components/_index";

const BannerTech = () => {
  return (
    <section id="bannerTech">
      <div className="section-background">
        <div className="wrapper">
          <main>
            <div className="flex column">
              <div className="content">
                <h3 className="reverse">
                  Inwestuj w technologię i design, <wbr />
                  dzięki którym sięgniesz gwiazd.
                </h3>
                <div className="big-title">
                  <span className="techBanner-title">
                    Zbudujmy <br />
                    coś wielkiego.
                  </span>
                </div>
                <p className="reverse">
                  Wspólnie budujmy przyszłość internetu, definiujmy na nowo to,
                  jak robi się aplikacje i dostarczajmy ludziom niesamowitych
                  doświadczeń.
                </p>
              </div>
              <div className="link-container">
                <a className="contact-link" href="#form">
                  <Button className="transparent">Ruszamy</Button>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
};

export default BannerTech;
