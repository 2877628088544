import "./Services.scss";

import { Button, OfferBox } from "../../../components/_index";
import {
  iconCircle,
  iconHexagon,
  iconSquare,
  iconTriangle,
} from "../../../img/_index";

import { useState } from "react";

const contentDesign =
  "Kompletne projektowanie produktów cyfrowych od pierwszych szkiców i prototypowania UX po finalny projekt UI. Tworzenie contentu oraz kreatywne wsparcie dla marek tych produktów.";
const contentCode =
  "Wytwarzanie kodu w technologii dopasowanej do Twojej aplikacji i jej rozmiaru. Ciągłe testowanie jakości oprogramowania i monitorowanie jej działania po wystartowaniu.";
const contentResearch =
  "Powiew świeżego powietrza w świecie kodu, biznesu i designu. Badanie Twojej apki, stronki lub e-commerce’a pod kątem UX, performance’u i zgodność z wymaganiami biznesowymi i technicznymi.";
const contentAutomate =
  "Pozwalamy zaoszczędzić czas i pieniądze. Żmudne, rutynowe i czasochłonne czynności, za pomocą najnowszych rozwiązań technologicznych zamienimy w algorytmy.";

const linkDesign = "ZAPROJEKTUJMY COŚ";
const linkCode = "ZAKODŹMY COŚ";
const linkResearch = "ZBADAJMY COŚ";
const linkAutomate = "ZAUTOMATYZUJMY COŚ";

const Services = () => {
  const [hover1, setHover1] = useState(true);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const hoverHandler1 = () => {
    setHover1(true);
    setHover2(false);
    setHover3(false);
    setHover4(false);
  };
  const hoverHandler2 = () => {
    setHover2(true);
    setHover1(false);
    setHover3(false);
    setHover4(false);
  };
  const hoverHandler3 = () => {
    setHover3(true);
    setHover1(false);
    setHover2(false);
    setHover4(false);
  };
  const hoverHandler4 = () => {
    setHover4(true);
    setHover1(false);
    setHover2(false);
    setHover3(false);
  };

  return (
    <section id="services">
      <div className="section-background">
        <div className="wrapper">
          <h1 className="small black upper">Oferta</h1>
          <h3 className="subtitle">
            Przesuwamy granicę kreatywności i&nbsp;technologii, żeby Twój biznes
            mógł rozwijać się w cyfrowej erze.
          </h3>
          <div className="offers">
            <OfferBox
              onMouseEnter={() => hoverHandler1()}
              hover={hover1 ? "hover" : ""}
              shape={iconCircle}
              link={linkDesign}
              text={contentDesign}
              offer={"Design"}
            />
            <OfferBox
              onMouseEnter={() => hoverHandler2()}
              hover={hover2 ? "hover" : ""}
              shape={iconTriangle}
              link={linkCode}
              text={contentCode}
              offer={"Development"}
            />
            <OfferBox
              onMouseEnter={() => hoverHandler3()}
              hover={hover3 ? "hover" : ""}
              shape={iconHexagon}
              link={linkResearch}
              text={contentResearch}
              offer={"Badania i analizy"}
            />
            <OfferBox
              onMouseEnter={() => hoverHandler4()}
              hover={hover4 ? "hover" : ""}
              shape={iconSquare}
              link={linkAutomate}
              text={contentAutomate}
              offer={"Automatyzacja"}
            />
          </div>
          <a className="contact__btn hidden" href="#form">
            <Button className="reverse">Napisz do nas</Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Services;
