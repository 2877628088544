import "./WorkBox.scss";

const WorkBox = ({ onMouseEnter, hover, title, content, icon }) => {
  return (
    <div className={`work-box ${hover}`} onMouseEnter={onMouseEnter}>
      <div className="box">
        <img src={icon} alt="" />
      </div>
      <div className="title flex">
        <h3>{title}</h3>
      </div>
      <div className="content flex">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default WorkBox;
