const IconInstagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="instagram" transform="translate(-0.005)">
        <g id="Group_270" data-name="Group 270" transform="translate(0.005)">
          <g id="Group_269" data-name="Group 269" transform="translate(0)">
            <path
              id="Path_2845"
              data-name="Path 2845"
              d="M18,0H6A6.018,6.018,0,0,0,0,6V18a6.019,6.019,0,0,0,6,6H18a6.019,6.019,0,0,0,6-6V6A6.018,6.018,0,0,0,18,0Zm4,18a4,4,0,0,1-4,4h-12a4,4,0,0,1-4-4V6a4,4,0,0,1,4-4H18a4,4,0,0,1,4,4Z"
              transform="translate(-0.005)"
            />
          </g>
        </g>
        <g
          id="Group_272"
          data-name="Group 272"
          transform="translate(16.683 3.924)"
        >
          <g id="Group_271" data-name="Group 271">
            <circle
              id="Ellipse_1"
              data-name="Ellipse 1"
              cx="1.699"
              cy="1.699"
              r="1.699"
            />
          </g>
        </g>
        <g
          id="Group_274"
          data-name="Group 274"
          transform="translate(5.208 5.203)"
        >
          <g id="Group_273" data-name="Group 273">
            <path
              id="Path_2846"
              data-name="Path 2846"
              d="M109.2,102.4a6.8,6.8,0,1,0,6.8,6.8A6.8,6.8,0,0,0,109.2,102.4Zm0,11.329a4.531,4.531,0,1,1,4.531-4.532A4.531,4.531,0,0,1,109.2,113.729Z"
              transform="translate(-102.405 -102.4)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconInstagram;
