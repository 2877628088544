import "./ContactForm.scss";

import { FadeIn, FadeOut } from "../../hooks/_index";

import Form from "./Form/Form";
import ThankUPage from "./ThankUPage/ThankUPage";
import { useState } from "react";

const ContactForm = () => {
  const [regards, setRegards] = useState(false);

  const nextStep = () => {
    if (regards === false) {
      return (
        // <FadeOut>
        <Form setRegards={setRegards} />
        // </FadeOut>
      );
    } else {
      return (
        <FadeIn>
          <ThankUPage />
        </FadeIn>
      );
    }
  };
  return <section id="contactForm">{nextStep()}</section>;
};

export default ContactForm;
