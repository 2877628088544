import "../Regulations.scss";

import { Contact } from "../../_views-components/_index";
import { Header } from "../../../components/_index";

const Policy = () => {
  return (
    <>
      <Header color="black" />

      <div id="policy">
        <div className="section-background">
          <div className="wrapper">
            <main>
              <h1 className="small black upper bold">Polityka prywatnosci</h1>
              <section className="content">
                <article>
                  <div className="title">
                    <h3>postanowienia ogólne</h3>
                  </div>
                  <div className="text">
                    <p>
                      <strong>1.</strong> Administratorem danych osobowych
                      zbieranych za pośrednictwem strony internetowej
                      www.itdone.pl jest Piotr Juchniewicz wykonujący/a
                      działalność gospodarczą pod firmą PIOTR JUCHNIEWICZ, adres
                      siedziby: Biedaszki 20, 11-400, Biedaszki, Polska, adres
                      do doręczeń: Biedaszki 20, 11-400, Biedaszki, Polska, NIP:
                      7422265523, REGON: 382464930, wpisaną do Centralnej
                      Ewidencji i&nbsp;Informacji o&nbsp;Działalności
                      Gospodarczej, adres poczty elektronicznej: get@itdone.pl,
                      dalej &bdquo;Administrator&rdquo;, będący/a jednoczeście
                      Usługodawcą. Miejsce wykonywania działalności: Biedaszki
                      20, 11-400, Biedaszki, Polska, adres do doręczeń:
                      Biedaszki 20, 11-400, Biedaszki, Polska, NIP: 7422265523,
                      REGON: 382464930, adres poczty elektronicznej
                      (&nbsp;e-&nbsp;mail): get@itdone.pl, zwany/a dalej
                      &ldquo;Administratorem&rdquo;.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>2.</strong> Dane osobowe zbierane przez
                      Administratora za&nbsp;pośrednictwem strony internetowej
                      są przetwarzane zgodnie z&nbsp;Rozporządzeniem Parlamentu
                      Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27
                      kwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych w
                      związku z&nbsp;przetwarzaniem danych osobowych
                      i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych
                      oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
                      ochronie danych), zwane dalej RODO oraz ustawą
                      o&nbsp;ochronie danych osobowych z&nbsp;dnia 10 maja 2018
                      r.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>
                      RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I&nbsp;ZAKRES
                      ZBIERANIA DANYCH
                    </h3>
                  </div>
                  <div className="text">
                    <p className="subtitle">
                      <strong>1.</strong> CEL PRZETWARZANIA I&nbsp;PODSTAWA
                      PRAWNA.
                    </p>
                    <p>
                      Administrator przetwarza dane osobowe
                      za&nbsp;pośrednictwem strony www.itdone.pl
                      w&nbsp;przypadku:
                    </p>
                    <p>
                      <strong>a.</strong> skorzystania przez użytkownika
                      &nbsp;przetwarzane na&nbsp;podstawie art. 6 ust. 1 lit. f)
                      RODO jako prawnie usprawiedliwiony interes Administratora.
                    </p>
                    <p>
                      <strong>b.</strong> zapisania się przez użytkownika
                      do&nbsp;Newslettera w celu przesyłania informacji
                      handlowych drogą elektroniczną. Dane osobowe
                      są&nbsp;przetwarzane po wyrażeniu odrębnej zgody, na
                      podstawie art. 6 ust. 1 lit. a) RODO.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="subtitle">
                      <strong>2.</strong> RODZAJ PRZETWARZANYCH DANYCH
                      OSOBOWYCH.&nbsp;
                    </p>
                    <p>
                      Administrator przetwarza następujące kategorie danych
                      osobowych użytkownika:
                    </p>
                    <p>
                      <strong>a.</strong> Imię i nazwisko,
                    </p>
                    <p>
                      <strong>b.</strong> Data urodzenia,
                    </p>
                    <p>
                      <strong>c.</strong> Adres (zamieszkania),
                    </p>
                    <p>
                      <strong>d.</strong> Adres e-mail,
                    </p>
                    <p>
                      <strong>e.</strong> Numer telefonu,
                    </p>
                    <p>
                      <strong>f.</strong> NIP
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="subtitle">
                      <strong>3.</strong> OKRES ARCHIWIZACJI DANYCH OSOBOWYCH.
                    </p>
                    <p>
                      Dane osobowe użytkowników przechowywane są
                      przez&nbsp;Administratora:
                    </p>
                    <p>
                      <strong>a.</strong> w przypadku, gdy podstawą
                      przetwarzania danych jest wykonanie umowy, tak długo, jak
                      jest to niezbędne do&nbsp;wykonania umowy,
                      a&nbsp;po&nbsp;tym czasie przez okres odpowiadający
                      okresowi przedawnienia roszczeń. Jeżeli przepis szczególny
                      nie stanowi inaczej, termin przedawnienia wynosi lat
                      sześć, a&nbsp;dla roszczeń o świadczenia okresowe oraz
                      roszczeń związanych z&nbsp;prowadzeniem działalności
                      gospodarczej -&nbsp;trzy&nbsp;lata.
                    </p>
                    <p>
                      <strong>b.</strong> w przypadku, gdy podstawą
                      przetwarzania danych jest zgoda, tak długo, aż&nbsp;zgoda
                      nie zostanie odwołana, a&nbsp;po odwołaniu zgody przez
                      okres czasu odpowiadający okresowi przedawnienia roszczeń
                      jakie może podnosić Administrator i&nbsp;jakie mogą być
                      podnoszone wobec niego. Jeżeli przepis szczególny nie
                      stanowi inaczej, termin przedawnienia wynosi lat sześć,
                      a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz
                      roszczeń związanych z&nbsp;prowadzeniem działalności
                      gospodarczej -&nbsp;trzy&nbsp;lata.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>4.</strong> Podczas korzystania ze&nbsp;strony
                      internetowej mogą być pobierane dodatkowe informacje,
                      w&nbsp;szczególności: adres IP przypisany do komputera
                      użytkownika lub zewnętrzny adres IP dostawcy Internetu,
                      nazwa domeny, rodzaj przeglądarki, czas dostępu, typ
                      systemu operacyjnego.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>5.</strong> Od użytkowników mogą być także
                      gromadzone dane nawigacyjne, w&nbsp;tym informacje o
                      linkach i&nbsp;odnośnikach, w&nbsp;które zdecydują się
                      kliknąć lub innych czynnościach, podejmowanych na stronie
                      internetowej. Podstawą prawną tego rodzaju czynności jest
                      prawnie uzasadniony interes Administratora (art. 6 ust. 1
                      lit. f RODO), polegający na ułatwieniu korzystania z usług
                      świadczonych drogą elektroniczną oraz na poprawie
                      funkcjonalności tych usług.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>6.</strong> Podanie danych osobowych przez
                      użytkownika jest dobrowolne.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>7.</strong> Dane osobowe będą przetwarzane także
                      w&nbsp;sposób zautomatyzowany w formie profilowania,
                      o&nbsp;ile użytkownik wyrazi na to zgodę na podstawie art.
                      6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie
                      przypisanie danej osobie profilu w celu podejmowania
                      dotyczących jej decyzji bądź analizy lub przewidywania jej
                      preferencji, zachowań i&nbsp;postaw.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>8.</strong> Administrator dokłada szczególnej
                      staranności w celu ochrony interesów osób, których dane
                      dotyczą, a&nbsp;w&nbsp;szczególności zapewnia, że zbierane
                      przez niego dane są:
                    </p>
                    <p>
                      <strong>a.</strong> przetwarzane zgodnie z prawem,
                    </p>
                    <p>
                      <strong>b.</strong> zbierane dla oznaczonych, zgodnych z
                      prawem celów&nbsp;i&nbsp;niepoddawane dalszemu
                      przetwarzaniu niezgodnemu z&nbsp;tymi celami,
                    </p>
                    <p>
                      <strong>c.</strong> merytorycznie poprawne
                      i&nbsp;adekwatne w&nbsp;stosunku do celów, w&nbsp;jakich
                      są&nbsp;przetwarzane oraz przechowywane w&nbsp;postaci
                      umożliwiającej identyfikację osób, których dotyczą, nie
                      dłużej niż jest to niezbędne do osiągnięcia celu
                      przetwarzania.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>UDOSTĘPNIENIE DANYCH OSOBOWYCH</h3>
                  </div>
                  <div className="text">
                    <p>
                      <strong>1.</strong> Dane osobowe użytkowników przekazywane
                      są dostawcom usług, z&nbsp;których korzysta Administrator
                      przy prowadzeniu strony internetowej. Dostawcy usług,
                      którym przekazywane są dane osobowe, w&nbsp;zależności
                      od&nbsp;uzgodnień umownych i&nbsp;okoliczności, albo
                      podlegają poleceniom Administratora co do celów
                      i&nbsp;sposobów przetwarzania tych danych (podmioty
                      przetwarzające) albo samodzielnie określają cele
                      i&nbsp;sposoby ich przetwarzania (administratorzy).
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>2.</strong> Dane osobowe użytkowników&nbsp;są
                      przechowywane wyłącznie na terenie Europejskiego Obszaru
                      Gospodarczego (EOG).
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>
                      PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ
                      ICH&nbsp;POPRAWIANIA
                    </h3>
                  </div>
                  <div className="text">
                    <p>
                      <strong>1.</strong> Osoba, której dane dotyczą, ma prawo
                      dostępu do treści swoich danych osobowych oraz prawo ich
                      sprostowania, usunięcia, ograniczenia przetwarzania, prawo
                      do przenoszenia danych, prawo wniesienia sprzeciwu, prawo
                      do cofnięcia zgody w dowolnym momencie bez wpływu na
                      zgodność z&nbsp;prawem przetwarzania, którego dokonano na
                      podstawie zgody przed jej cofnięciem.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>2.</strong> Podstawy prawne żądania użytkownika:
                    </p>
                    <p>a. Dostęp do danych &ndash; art. 15 RODO</p>
                    <p>
                      <strong>b.</strong> Sprostowanie danych &ndash; art. 16
                      RODO.
                    </p>
                    <p>
                      <strong>c.</strong> Usunięcie danych (tzw. prawo do bycia
                      zapomnianym) &ndash; art. 17 RODO.
                    </p>
                    <p>
                      <strong>d.</strong> Ograniczenie przetwarzania &ndash;
                      art. 18 RODO.
                    </p>
                    <p>e. Przeniesienie danych &ndash; art. 20 RODO.</p>
                    <p>f. Sprzeciw &ndash; art. 21 RODO</p>
                    <p>g. Cofnięcie zgody &ndash; art. 7 ust. 3 RODO.</p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>3.</strong> W celu realizacji uprawnień,
                      o&nbsp;których mowa w pkt 2 można wysłać stosowną
                      wiadomość e-mail na adres: get@itdone.pl.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>4.</strong> W sytuacji wystąpienia przez
                      użytkownika z&nbsp;uprawnieniem wynikającym z powyższych
                      praw, Administrator spełnia żądanie albo odmawia jego
                      spełnienia niezwłocznie, nie później jednak niż w ciągu
                      miesiąca po jego otrzymaniu. Jeżeli jednak
                      -&nbsp;z&nbsp;uwagi na skomplikowany charakter żądania lub
                      liczbę żądań &ndash; Administrator nie będzie mógł spełnić
                      żądania w&nbsp;ciągu miesiąca, spełni je w ciągu kolejnych
                      dwóch miesięcy informując użytkownika uprzednio w terminie
                      miesiąca od otrzymania żądania -&nbsp;o&nbsp;zamierzonym
                      przedłużeniu terminu oraz&nbsp;jego przyczynach.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>5.</strong> W przypadku stwierdzenia,
                      że&nbsp;przetwarzanie danych osobowych narusza przepisy
                      RODO, osoba, której dane dotyczą, ma&nbsp;prawo wnieść
                      skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>PLIKI “COOKIES”</h3>
                  </div>
                  <div className="text">
                    <p>
                      <strong>1.</strong> Strona Administratora używa plików
                      &bdquo;cookies&rdquo;.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>2.</strong> Instalacja plików
                      &bdquo;cookies&rdquo; jest konieczna do prawidłowego
                      świadczenia usług na stronie internetowej. W&nbsp;plikach
                      &bdquo;cookies&rdquo; znajdują się informacje niezbędne do
                      prawidłowego funkcjonowania strony, a&nbsp;także dają one
                      także możliwość opracowywania ogólnych statystyk odwiedzin
                      strony internetowej.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>3.</strong> W ramach strony stosowane są rodzaje
                      plików &ldquo;cookies&rdquo;: sesyjne i&nbsp;stałe
                    </p>
                    <p>
                      <strong>a.</strong> &bdquo;Cookies&rdquo;
                      &bdquo;sesyjne&rdquo; są plikami tymczasowymi, które
                      przechowywane są w&nbsp;urządzeniu końcowym użytkownika do
                      czasu wylogowania (opuszczenia strony).
                    </p>
                    <p>
                      <strong>b.</strong> &bdquo;Stałe&rdquo; pliki
                      &bdquo;cookies&rdquo; przechowywane są w urządzeniu
                      końcowym użytkownika przez czas określony
                      w&nbsp;parametrach plików&bdquo;cookies&rdquo; lub do
                      czasu ich usunięcia przez użytkownika.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>4.</strong> Administrator wykorzystuje własne
                      pliki cookies w&nbsp;celu lepszego poznania sposobu
                      interakcji użytkownika w&nbsp;zakresie zawartości strony.
                      Pliki gromadzą informacje o&nbsp;sposobie korzystania ze
                      strony internetowej przez użytkownika, typie strony,
                      z&nbsp;jakiej użytkownik został przekierowany oraz liczbie
                      odwiedzin i&nbsp;czasie wizyty użytkownika na stronie
                      internetowej. Informacje te nie rejestrują konkretnych
                      danych osobowych użytkownika, lecz&nbsp;służą do
                      opracowania statystyk korzystania ze strony.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>5.</strong> Użytkownik ma prawo zadecydowania
                      w&nbsp;zakresie dostępu plików &bdquo;cookies&rdquo; do
                      swojego komputera poprzez ich uprzedni wybór w&nbsp;oknie
                      swojej przeglądarki. &nbsp;Szczegółowe informacje
                      o&nbsp;możliwości i&nbsp;sposobach obsługi plików
                      &bdquo;cookies&rdquo; dostępne są w&nbsp;ustawieniach
                      oprogramowania (przeglądarki internetowej).
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>POSTANOWIENIA KOŃCOWE</h3>
                  </div>
                  <div className="text">
                    <p>
                      <strong>1.</strong> Administrator stosuje środki
                      techniczne i&nbsp;organizacyjne zapewniające ochronę
                      przetwarzanych danych osobowych odpowiednią do zagrożeń
                      oraz kategorii danych objętych ochroną,
                      a&nbsp;w&nbsp;szczególności zabezpiecza dane przed ich
                      udostępnieniem osobom nieupoważnionym, zabr/aniem przez
                      osobę nieuprawnioną, przetwarzaniem z&nbsp;naruszeniem
                      obowiązujących przepisów&nbsp;oraz zmianą, utratą,
                      uszkodzeniem lub zniszczeniem.
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <strong>2.</strong> Administrator udostępnia odpowiednie
                      środki techniczne zapobiegające pozyskiwaniu
                      i&nbsp;modyfikowaniu przez osoby nieuprawnione, danych
                      osobowych przesyłanych drogą elektroniczną.
                    </p>
                    <p>
                      W sprawach nieuregulowanych niniejszą Polityką prywatności
                      stosuje się odpowiednio przepisy RODO oraz inne właściwe
                      przepisy prawa polskiego.
                    </p>
                  </div>
                </article>
              </section>
            </main>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Policy;
