import "./Input.scss";

const Input = ({
  id,
  placeholder,
  type,
  name,
  register,
  error,
  validationRules,
}) => {
  return (
    <div className="inputBox">
      <input
        type={type}
        id={id}
        required
        {...register(name, { ...validationRules })}
        name={name}
      />
      <label htmlFor={id}>{placeholder}</label>
      <span className="line"></span>
      {error ? <span className="formError">{error.message}</span> : ""}
    </div>
  );
};

export default Input;
