import "./CheckBox.scss";

import { useState } from "react";

const CheckBox = ({ children, id, name, register }) => {
  return (
    <label className={"container"}>
      {children}
      <input
        id={id}
        name={name}
        type={"checkbox"}
        {...register(name, { required: {value: true} })}

      />
      <span className={"checkmark"}></span>
    </label>
  );
};

export default CheckBox;
