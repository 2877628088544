const IconLinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="linkedin_1_"
        data-name="linkedin (1)"
        d="M20.484,0H3.516A3.52,3.52,0,0,0,0,3.516V20.484A3.52,3.52,0,0,0,3.516,24H20.484A3.52,3.52,0,0,0,24,20.484V3.516A3.52,3.52,0,0,0,20.484,0Zm-12,19.031H5.672V9.188H8.484Zm0-11.25H5.672V4.969H8.484Zm9.844,11.25H15.516V13.406a1.406,1.406,0,0,0-2.812,0v5.625H9.891V9.188H12.7v.53a6.1,6.1,0,0,1,2.109-.53,3.681,3.681,0,0,1,3.516,3.735Zm0,0"
      />
    </svg>
  );
};

export default IconLinkedIn;
