import "./Chip.scss";

import React from "react";

export default class Chip extends React.Component {
  render() {
    return (
      <span
        className={
          "chip " + (this.props.active ? " active " : "") + this.props.type
        }
        tabIndex="0"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </span>
    );
  }
}
