import "./TextArea.scss";

import { useState } from "react";

const TextArea = ({ id, placeholder, name, register }) => {
  const [characterCount, setCharacterCount] = useState(0);
  const areaHandler = (e) => {
    setCharacterCount(e.target.value.length);
  };

  const tx = document.getElementsByTagName("textarea");
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute(
      "style",
      "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
    );
    tx[i].addEventListener("input", OnInput, false);
  }

  function OnInput() {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  }

  // const preventEnter = (e) => {
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //   }
  // };
  return (
    <div className="areaBox">
      <textarea
        type="text"
        id={id}
        name={name}
        required
        rows="1"
        // maxRows="8"
        maxLength="512"
        onKeyUp={areaHandler}
        {...register(name, { })}
        // onKeyDown={(e) => preventEnter(e)}
      />
      <label htmlFor={id}>{placeholder}</label>
      <span className="line">
        <span className="counter small">{characterCount} / 512</span>
      </span>
    </div>
  );
};

export default TextArea;
