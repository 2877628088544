import "./Contact.scss";

import { ContactForm, Footer } from "../../../components/_index";

const Contact = () => {
  return (
    <section id="contact">
      <div className="section-background grey">
        <main className="wrapper">
          <ContactForm />
          <Footer />
        </main>
      </div>
    </section>
  );
};

export default Contact;
