const IconEmail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.083"
      height="19.003"
      viewBox="0 0 24.083 19.003"
    >
      <path
        id="mail-inbox-app"
        d="M18.439,73H5.644A5.651,5.651,0,0,1,0,67.359V59.644A5.651,5.651,0,0,1,5.644,54H18.439a5.651,5.651,0,0,1,5.644,5.644v7.714A5.651,5.651,0,0,1,18.439,73ZM5.644,55.881a3.767,3.767,0,0,0-3.763,3.763v7.714a3.767,3.767,0,0,0,3.763,3.763H18.439A3.767,3.767,0,0,0,22.2,67.359V59.644a3.767,3.767,0,0,0-3.763-3.763Zm9.844,9.285,4.65-3.552A.941.941,0,0,0,19,60.12l-4.649,3.551a3.772,3.772,0,0,1-4.561,0l-4.505-3.5a.941.941,0,0,0-1.155,1.485l4.509,3.5.007.005a5.658,5.658,0,0,0,6.847,0Z"
        transform="translate(0 -54)"
      />
    </svg>
  );
};

export default IconEmail;
