import "./Button.scss";

const btnClass = "btn";
const btnActiveClasses = "active";

export default function Button({
  className,
  active = false,
  onClick,
  children,
  disabled,
}) {
  return (
    <button
      className={`btn ${className} ${active ? active : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
