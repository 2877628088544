import "./Footer.scss";

import { IconEmail, IconPhone } from "../../img/_index";
import { IconFb, IconInstagram, IconLinkedIn } from "../../img/_index";
import { Logo, MenuItem } from "../_index";

import { Link } from "react-router-dom";

const loveMotto = "**IT MADE WITH LOVE <3";
const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer__container">
        <div className="testimonial">
          <Logo />
          <p className="vsmall">
            Łączymy zaskakujący design z&nbsp;nowoczesnymi technologiami,
            <br /> żeby tworzyć niesamowite i&nbsp;niezawodne apki.
          </p>
          <span className="vsmall">Get **IT Done</span>
          <span className="vsmall">Lęborska 3B 80-300 Gdańsk</span>
        </div>
        <div className="navigation">
          <nav className="nav">
            <ul className="flex column">
              <MenuItem linkTo="/#home">Home</MenuItem>
              {/* <MenuItem linkTo="/#portfolio">Case Study</MenuItem> */}
              <MenuItem linkTo="/#services">Usługi</MenuItem>
              <MenuItem linkTo="/#about">O nas</MenuItem>
              <MenuItem linkTo="/#work">Jak pracujemy</MenuItem>
            </ul>
          </nav>
          <aside className="regulations">
            <ul className="flex column">
              <Link to="/regulations/policy">Polityka Prywatności</Link>
              <Link to="/regulations/statute">Regulamin strony</Link>
            </ul>
          </aside>
        </div>
        <div className="contacts">
          <div className="contact">
            <div className="contact__container">
              <div className="phone flex">
                <IconPhone />
                <a className="" href="+48 727 659 360" type="phone">
                  +48 727 659 360
                </a>
              </div>
              <div className="email flex">
                <IconEmail />
                <a className="" href="mailto:get@itdone.pl" type="email">
                  get@itdone.pl
                </a>
              </div>
            </div>
          </div>
          <div className="social">
            <div className="social__container">
              <div className="social__container__links flex">
                <a
                  href="https://www.facebook.com/itdonepl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconFb />
                </a>
                <a
                  href="https://www.instagram.com/itdonepl/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/itdonepl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconLinkedIn />
                </a>
                <span>@itdone.pl</span>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights flex">
          <p className="vsmall">{loveMotto}</p>
          <p className="vsmall">Copyright 2021 Get **IT Done</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
