import "./Home.scss";

import { IconEmail, IconPhone } from "../../../img/_index";
import { iconArrow, xMovie } from "../../../img/_index";

import { Button } from "../../../components/_index";
import { HashLink as Link } from "react-router-hash-link";

const Home = () => {
  return (
    <section id="home">
      <div className="section-background black">
        <main className="wrapper">
          <div className="hero flex column">
            <h1 className="reverse">
              Here’s to
              <br />
              crazy ideas
            </h1>
            <p className="reverse">
              Poznaj Software House, którego nie pomylisz z&nbsp;żadnym innym.
              W&nbsp;Get **IT Done wierzymy w&nbsp;niecodzienne pomysły, łączymy
              zaskakujący design z&nbsp;nowoczesnymi technologiami, żeby tworzyć
              niesamowite i&nbsp;niezawodne apki. Zróbmy razem coś szalonego,
              albo jak my to mówimy “let’s&nbsp;get&nbsp;**it&nbsp;done”.
            </p>
          </div>
          <div className="great-x">
            <div className="great-x__container">
              <video
                autoPlay="autoplay"
                loop="loop"
                muted
                playsInline="playsinline"
                preload="auto"
              >
                <source src={xMovie} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="contact">
            <div className="contact__container flex">
              <div className="phone flex">
                <IconPhone />
                <a className="reverse" href="+48 727 659 360" type="phone">
                  +48 727 659 360
                </a>
              </div>
              <div className="email flex">
                <IconEmail />
                <a
                  className="reverse"
                  href="mailto:get@itdone.pl"
                  type="email"
                >
                  get@itdone.pl
                </a>
              </div>
            </div>
            <aside>
              <Link to="/#services" className="reverse">
                <div className="aside__container">
                  <img src={iconArrow} alt="" />
                  <span className="reverse">poznaj nas lepiej</span>
                </div>
              </Link>
            </aside>
            <a className="contact__btn" href="#form">
              <Button type="">let's get it done</Button>
            </a>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Home;
