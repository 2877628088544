export const validationRules = {
  name: {
    required: {
      value: true,
      message: "To pole jest wymagane",
    },
    minLength: {
      value: "2",
      message: "Wpisz przynajmniej 2 znaki",
    },
    pattern: {
      value: /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,20}$/,
      message: "Wpisz tylko litery",
    },
    maxLength: {
      value: "64",
      message: "Wpisz maksymalnie 64 znaki",
    },
  },
  mail: {
    required: {
      value: true,
      message: "To pole jest wymagane",
    },
    minLength: {
      value: "6",
      message: "Wpisz przynajmniej 6 znaków",
    },
    maxLength: {
      value: "64",
      message: "Wpisz maksymalnie 64 znaki",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Niepoprawny adres email",
    },
  },
  phone: {
    minLength: {
      value: "7",
      message: "Wpisz przynajmniej 7 znaków",
    },
    maxLength: {
      value: "16",
      message: "Wpisz maksymalnie 16 znaków",
    },
    pattern: {
      value: /^[0-9 ()+]+$/,
      message: "Niepoprawny format telefonu",
    },
  },
  company: {
    minLength: {
      value: "2",
      message: "Wpisz przynajmniej 2 znaki",
    },
    maxLength: {
      value: "64",
      message: "Wpisz maksymalnie 64 znaki",
    },
  },
  message: {
    maxLength: {
      value: "512",
      message: "Wpisz maksymalnie 512 znaków",
    },
    required: {
      value: true,
      message: "To pole jest wymagane",
    },
  },
};
