import "./Cookie.scss";

import { useEffect, useState } from "react";

import { Button } from "../_index";
import { Link } from "react-router-dom";
import { iconCookie } from "../../img/_index";

const Cookie = () => {
  const [open, setOpen] = useState(true);

  const handleCookie = () => {
    // show cookie in console
    let cookie = document.cookie.split(";");
    console.log("cookie : ", cookie);
  };
  const createCookie = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
    document.cookie =
      cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
    localStorage.setItem("cookies_enabled", "1");
    setOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("cookies_enabled") === "1") {
      setOpen(false);
    }
  }, []);

  return (
    <div id="cookie" className={open === true ? "" : "hidden"}>
      <div className="section-background">
        <main className="flex">
          <div className="cookie-container flex">
            <img src={iconCookie} alt="cookies icon" />
            <p className="small">
              Strona wykorzystuje pliki cookies w celu jej prawidłowego
              funkcjonowania oraz w celach korzystania z narzędzi
              społecznościowych, analitycznych oraz marketingowych. Możesz w
              każdej chwili określić warunki przechowywania lub dostępu do
              plików cookies we własnej przeglądarce. Więcej informacji
              znajdziesz w naszej{" "}
              <Link to="/regulations/policy" className="small">
                Polityce Prywatności
              </Link>
              . Poczęstujesz nas ciasteczkiem?
            </p>
          </div>
          <Button
            className="reverse"
            onClick={() => {
              createCookie("gid-cookie", "true", 2);
              handleCookie();
            }}
          >
            smacznego
          </Button>
        </main>
      </div>
    </div>
  );
};

export default Cookie;
