import { HashLink as Link } from "react-router-hash-link";

const MenuItem = ({ linkTo, className, children, onClick }) => {
  return (
    <li className={"menuItem "}>
      <Link to={linkTo} className={className} onClick={onClick}>
        {children}
      </Link>
    </li>
  );
};

export default MenuItem;
