const IconFb = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="facebook"
        d="M20.484,0H3.516A3.52,3.52,0,0,0,0,3.516V20.484A3.52,3.52,0,0,0,3.516,24h7.078V15.516H7.781V11.3h2.813V8.438a4.223,4.223,0,0,1,4.219-4.219h4.266V8.438H14.813V11.3h4.266l-.7,4.219H14.813V24h5.672A3.52,3.52,0,0,0,24,20.484V3.516A3.52,3.52,0,0,0,20.484,0Zm0,0"
      />
    </svg>
  );
};

export default IconFb;
