import {
  AboutUs,
  BannerTech,
  Career,
  Contact,
  Home,
  Services,
  Work,
} from "../_views-components/_index";

import { Header } from "../../components/_index";

const Landing = () => {
  return (
    <>
      <Header color="black" />
      <Home />
      <Services />
      <AboutUs />
      <BannerTech />
      <Work />
      {/* <Career /> */}
      <Contact />
    </>
  );
};

export default Landing;
