import "./Logo.scss";

const Logo = ({ color }) => {
  return (
    <div className={`logo ${color}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="25.865"
        viewBox="0 0 190 25.865"
      >
        <g
          id="Group_255"
          data-name="Group 255"
          transform="translate(-359.817 -400.967)"
        >
          <path
            id="Path_2805"
            data-name="Path 2805"
            d="M363.961,403.706a4.208,4.208,0,0,1,6.41,0l3.723,3.755-3.172,3.173-2.557-2.59a1.536,1.536,0,0,0-2.428,0l-1.586,1.586v8.546l1.975,1.975a1.517,1.517,0,0,0,2.428,0l1.392-1.424c0-1.23-.583-1.813-1.716-1.813h-1.036v-4.532h2.752a4.185,4.185,0,0,1,4.532,4.532v3.626l-3.917,3.949a4.243,4.243,0,0,1-6.442,0l-4.5-4.5V407.817Z"
            transform="translate(0 -0.926)"
          />
          <path
            id="Path_2806"
            data-name="Path 2806"
            d="M438.062,445.043l-3.2,3.2-5.05-5.05a5.053,5.053,0,0,1-1.133-3.4v-5.212a4.154,4.154,0,0,1,4.532-4.532h3.529c3.3,0,5.827,1.2,5.827,3.561a5.4,5.4,0,0,1-1.845,3.658l-5.212,5.212Zm-1.651-9.291a.756.756,0,0,0-.712-1.2h-.842a1.482,1.482,0,0,0-1.684,1.651v2.784Z"
            transform="translate(-50.696 -21.415)"
          />
          <path
            id="Path_2807"
            data-name="Path 2807"
            d="M486.609,417.4H487a1.5,1.5,0,0,0,1.684-1.715v-2.816h4.532V417.4h3.237v4.532h-3.237v5.083a1.525,1.525,0,0,0,1.716,1.716h.745v4.532h-2.46a4.185,4.185,0,0,1-4.532-4.532v-6.8h-2.072Z"
            transform="translate(-93.35 -8.765)"
          />
          <path
            id="Path_2808"
            data-name="Path 2808"
            d="M569.381,411.974l-3.237-3.237,1.1-1.069a1.485,1.485,0,0,0,0-2.4l-1.1-1.1,3.237-3.2,2.3,2.3,2.3-2.3,3.2,3.2-1.1,1.1a1.522,1.522,0,0,0,0,2.4l1.1,1.069-3.2,3.237-2.3-2.331Z"
            transform="translate(-151.907)"
          />
          <path
            id="Path_2809"
            data-name="Path 2809"
            d="M625.229,411.974l-3.237-3.237,1.1-1.069a1.486,1.486,0,0,0,0-2.4l-1.1-1.1,3.237-3.2,2.3,2.3,2.3-2.3,3.2,3.2-1.1,1.1a1.522,1.522,0,0,0,0,2.4l1.1,1.069-3.2,3.237-2.3-2.331Z"
            transform="translate(-193.025)"
          />
          <path
            id="Path_2810"
            data-name="Path 2810"
            d="M682.6,422.409h.388v4.532h-2.1a4.185,4.185,0,0,1-4.532-4.532V410.5a1.48,1.48,0,0,0-1.684-1.684h-.388V404.28h2.072a4.154,4.154,0,0,1,4.532,4.532v11.881A1.526,1.526,0,0,0,682.6,422.409Z"
            transform="translate(-231.521 -2.439)"
          />
          <path
            id="Path_2811"
            data-name="Path 2811"
            d="M717.737,408.812h-3.69V404.28h15.312v4.532h-3.69a1.479,1.479,0,0,0-1.683,1.684v16.445h-4.532V410.5A1.5,1.5,0,0,0,717.737,408.812Z"
            transform="translate(-260.8 -2.439)"
          />
          <path
            id="Path_2812"
            data-name="Path 2812"
            d="M808.462,408.812h-1.133V404.28h12.658a4.154,4.154,0,0,1,4.532,4.532v13.6a4.216,4.216,0,0,1-4.532,4.532h-9.809V410.5A1.529,1.529,0,0,0,808.462,408.812Zm11.525,11.881V410.5a1.479,1.479,0,0,0-1.683-1.684H814.71v13.6H818.3A1.5,1.5,0,0,0,819.987,420.693Z"
            transform="translate(-329.479 -2.439)"
          />
          <path
            id="Path_2813"
            data-name="Path 2813"
            d="M894.009,429.265l4.208,4.208a4.279,4.279,0,0,1,0,6.022l-4.208,4.208a4.289,4.289,0,0,1-6.054,0l-4.176-4.208a4.28,4.28,0,0,1,0-6.022l4.176-4.208A4.288,4.288,0,0,1,894.009,429.265ZM892.2,433.8a1.536,1.536,0,0,0-2.428,0l-1.457,1.489a1.485,1.485,0,0,0,0,2.4l1.457,1.489a1.536,1.536,0,0,0,2.428,0l1.489-1.489a1.522,1.522,0,0,0,0-2.4Z"
            transform="translate(-384.853 -19.914)"
          />
          <path
            id="Path_2814"
            data-name="Path 2814"
            d="M955.1,436.27a1.48,1.48,0,0,0-1.684-1.683h-.388v-4.532H963.61a4.17,4.17,0,0,1,4.532,4.532v5.083a1.5,1.5,0,0,0,1.684,1.716h.388v4.532h-2.072a4.169,4.169,0,0,1-4.532-4.532V436.27a1.5,1.5,0,0,0-1.716-1.683h-2.266v11.33H955.1Z"
            transform="translate(-436.746 -21.415)"
          />
          <path
            id="Path_2815"
            data-name="Path 2815"
            d="M1036.917,445.043l-3.2,3.2-5.05-5.05a5.052,5.052,0,0,1-1.133-3.4v-5.212a4.154,4.154,0,0,1,4.532-4.532h3.529c3.3,0,5.827,1.2,5.827,3.561a5.405,5.405,0,0,1-1.845,3.658l-5.212,5.212Zm-1.651-9.291a.756.756,0,0,0-.712-1.2h-.842a1.482,1.482,0,0,0-1.683,1.651v2.784Z"
            transform="translate(-491.6 -21.415)"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
