import "./OfferBox.scss";

import Button from "../Button/Button";

const OfferBox = ({ onMouseEnter, hover, shape, offer, text, link }) => {
  return (
    <div className={`offer ${hover}`} onMouseEnter={onMouseEnter}>
      <div className="offer__container flex column">
        <div className="offer__container__visible flex column">
          <div className="image-container">
            <img src={shape} alt="" />
          </div>
          <div className="text-container">
            {offer}
            <h3>{offer}</h3>
          </div>
        </div>
        <div className="offer__container__hidden">
          <p className="small">{text}</p>
          <a href="#form">
            <Button className="white">{link}</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default OfferBox;
