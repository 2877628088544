import "../Regulations.scss";

import { Contact } from "../../_views-components/_index";
import { Header } from "../../../components/_index";

const Statute = () => {
  return (
    <>
      <Header color="black" />

      <div id="policy">
        <div className="section-background">
          <div className="wrapper">
            <main>
              <h1 className="small black upper bold">regulamin strony</h1>
              <section className="content">
                <article>
                  <div className="title">
                    <h3 className="upper">postanowienia&nbsp;ogólne</h3>
                  </div>
                  <div className="text">
                    <p>
                      Strona&nbsp;www.itdone.pl działa&nbsp;na&nbsp;zasadach
                      określonych w&nbsp;niniejszym Regulaminie.
                    </p>
                    <p>
                      Regulamin określa&nbsp;rodzaje i zakres usług świadczonych
                      drogą elektroniczną przez Stronę www.itdone.pl, zasady
                      świadczenia&nbsp;tych usług, warunki
                      zawierania&nbsp;i&nbsp;rozwiązywania&nbsp;umów
                      o&nbsp;świadczenie usług drogą elektroniczną, a&nbsp;także
                      tryb postępowania reklamacyjnego.
                    </p>
                    <p>
                      Każdy Usługobiorca&nbsp;z chwilą podjęcia&nbsp;czynności
                      zmierzających do korzystania&nbsp;z Usług Elektronicznych
                      Strony www.itdone.pl, zobowiązany jest
                      do&nbsp;przestrzegania&nbsp;postanowień niniejszego
                      Regulaminu.
                    </p>
                    <p>
                      W&nbsp;sprawach nieuregulowanych w&nbsp;niniejszym
                      Regulaminie mają zastosowanie przepisy
                    </p>
                    <p>
                      Ustawy o&nbsp;świadczeniu usług drogą elektroniczną
                      z&nbsp;dnia&nbsp;18 lipca 2002 r. (Dz. U. Nr 144, poz.
                      1204 ze zm.),
                    </p>
                    <p>
                      Ustawy o&nbsp;prawach konsumenta&nbsp;z dnia&nbsp;30
                      maja&nbsp;2014 r. (Dz. U. 2014 poz. 827),
                    </p>
                    <p>
                      Ustawy Kodeks cywilny z dnia&nbsp;23 kwietnia&nbsp;1964 r.
                      (Dz. U. nr 16, poz. 93 ze zm.) oraz inne właściwe przepisy
                      prawa&nbsp;polskiego.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>definicje</h3>
                  </div>
                  <div className="text">
                    <p>
                      FORMULARZ KONTAKTOWY - formularz dostępny na&nbsp;Stronie
                      www.itdone.pl umożliwiający Usługobiorcy bezpośredni
                      kontakt z&nbsp;Usługodawcą.
                    </p>
                    <p>REGULAMIN - niniejszy regulamin Strony.</p>
                    <p>
                      USŁUGODAWCa&nbsp;- Piotr Juchniewicz
                      wykonujący/a&nbsp;działalność gospodarczą pod firmą PIOTR
                      JUCHNIEWICZ, adres siedziby: Biedaszki 20, 11-400,
                      Biedaszki, Polska, adres do&nbsp;doręczeń: Biedaszki 20,
                      11-400, Biedaszki, Polska, NIP: 7422265523, REGON:
                      382464930, wpisaną do&nbsp;Centralnej Ewidencji i
                      Informacji o Działalności Gospodarczej, adres poczty
                      elektronicznej: get@itdone.pl, tel. +48 695 485 076.
                    </p>
                    <p>
                      USŁUGOBIORCa&nbsp;- osoba&nbsp;fizyczna,
                      osoba&nbsp;prawna&nbsp;albo&nbsp;jednostka
                      organizacyjna&nbsp;nieposiadająca&nbsp;osobowości prawnej,
                      której ustawa&nbsp;przyznaje zdolność prawną
                      korzystająca&nbsp;z Usługi Elektronicznej.
                    </p>
                    <p>
                      USŁUGa&nbsp;ELEKTRONICZNa&nbsp;-
                      usługa&nbsp;świadczona&nbsp;drogą elektroniczną przez
                      margin: 120px 284px; Usługodawcę na&nbsp;rzecz
                      Usługobiorcy za&nbsp;pośrednictwem Strony.
                    </p>
                    <p>
                      NEWSLETTER -
                      Usługa&nbsp;Elektroniczna&nbsp;pozwalająca&nbsp;Usługobiorcy
                      na&nbsp;subskrybowanie i otrzymywanie na&nbsp;podany przez
                      Usługobiorcę adres e-mail bezpłatnych informacji
                      pochodzących od Usługodawcy.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>RODZAJ I ZAKRES USŁUG ELEKTRONICZNYCH</h3>
                  </div>
                  <div className="text">
                    <p>
                      Usługodawca&nbsp;umożliwia&nbsp;za&nbsp;pośrednictwem
                      Strony korzystanie z&nbsp;Usług Elektronicznych takich
                      jak:
                    </p>
                    <p>korzystanie z Formularza&nbsp;Kontaktowego,</p>
                    <p>korzystanie z Newslettera,</p>
                    <p>
                      Świadczenie Usług Elektronicznych na&nbsp;rzecz
                      Usługobiorców&nbsp;odbywa&nbsp;się na&nbsp;warunkach
                      określonych w&nbsp;Regulaminie.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>
                      WARUNKI ŚWIADCZENIa I&nbsp;ZAWIERANIa UMÓW
                      o&nbsp;ŚWIADCZENIE USŁUG ELEKTRONICZNYCH
                    </h3>
                  </div>
                  <div className="text">
                    <p>
                      Świadczenie Usług Elektronicznych określonych
                      w&nbsp;rozdziale III pkt. 1 Regulaminu przez Usługodawcę
                      jest nieodpłatne.
                    </p>
                    <p>Okres na&nbsp;jaki umowa&nbsp;zostaje zawarta:</p>
                    <p>
                      Umowa&nbsp;o&nbsp;świadczenie Usługi Elektronicznej
                      polegającej na umożliwieniu wysłania&nbsp;wiadomości
                      za&nbsp;pośrednictwem Formularza
                      Kontaktowego&nbsp;zawierana&nbsp;jest na&nbsp;czas
                      oznaczony i&nbsp;ulega rozwiązaniu z&nbsp;chwilą
                      wysłania&nbsp;wiadomości albo&nbsp;zaprzestania&nbsp;jej
                      wysyłania&nbsp;przez Usługobiorcę.
                    </p>
                    <p>
                      umowa&nbsp;o&nbsp;świadczenie Usługi Elektronicznej
                      polegającej na korzystaniu z
                      Newslettera&nbsp;zawierana&nbsp;jest na&nbsp;czas
                      nieoznaczony.
                    </p>
                    <p>
                      Wymagania&nbsp;techniczne niezbędne do&nbsp;współpracy
                      z&nbsp;systemem teleinformatycznym, którym posługuje się
                      Usługodawca:
                    </p>
                    <p>komputer z dostępem do&nbsp;Internetu,</p>
                    <p>dostęp do&nbsp;poczty elektronicznej,</p>
                    <p>przeglądarka&nbsp;internetowa,</p>
                    <p>
                      włączenie w&nbsp;przeglądarce internetowej Cookies oraz
                      Javascript.
                    </p>
                    <p>
                      Usługobiorca&nbsp;zobowiązany jest
                      do&nbsp;korzystania&nbsp;ze Strony w&nbsp;sposób zgodny
                      z&nbsp;prawem i dobrymi obyczajami mając na&nbsp;uwadze
                      poszanowanie dóbr osobistych i&nbsp;praw własności
                      intelektualnej osób trzecich.
                    </p>
                    <p>
                      Usługobiorca&nbsp;zobowiązany jest
                      do&nbsp;wprowadzania&nbsp;danych zgodnych ze stanem
                      faktycznym.
                    </p>
                    <p>
                      Usługobiorcę obowiązuje zakaz dostarczania&nbsp;treści
                      o&nbsp;charakterze bezprawnym.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>
                      WARUNKI ROZWIĄZYWANIa UMÓw o&nbsp;ŚWIADCZENIE USŁUG
                      ELEKTRONICZNYCH
                    </h3>
                  </div>
                  <div className="text">
                    <p>
                      Wypowiedzenie umowy o&nbsp;świadczenie Usługi
                      Elektronicznej:
                    </p>
                    <p>
                      Wypowiedzeniu może ulec umowa&nbsp;o&nbsp;świadczenie
                      Usługi Elektronicznej o&nbsp;charakterze ciągłym
                      i&nbsp;bezterminowym (np. korzystanie z Newslettera).
                    </p>
                    <p>
                      Usługobiorca&nbsp;może wypowiedzieć umowę ze skutkiem
                      natychmiastowym i bez wskazywania&nbsp;przyczyn poprzez
                      przesłanie stosownego
                      oświadczenia&nbsp;za&nbsp;pośrednictwem poczty
                      elektronicznej na&nbsp;adres: get@itdone.pl
                    </p>
                    <p>
                      Usługodawca&nbsp;może wypowiedzieć umowę
                      o&nbsp;świadczenie Usługi Elektronicznej
                      o&nbsp;charakterze ciągłym i&nbsp;bezterminowym
                      w&nbsp;przypadku, gdy
                      Usługobiorca&nbsp;narusza&nbsp;Regulamin,
                      w&nbsp;szczególności, gdy dostarcza&nbsp;treści
                      o&nbsp;charakterze bezprawnym po&nbsp;bezskutecznym
                      wcześniejszym wezwaniu do zaprzestania&nbsp;naruszeń
                      z&nbsp;wyznaczeniem odpowiedniego&nbsp;terminu.
                      Umowa&nbsp;w&nbsp;takim wypadku
                      wygasa&nbsp;po&nbsp;upływie 7 dni od dnia&nbsp;złożenia
                      oświadczenia&nbsp;woli o&nbsp;jej wypowiedzeniu
                      (okres&nbsp;wypowiedzenia).
                    </p>
                    <p>
                      Wypowiedzenie prowadzi do&nbsp;ustania&nbsp;stosunku
                      prawnego&nbsp;ze skutkiem na&nbsp;przyszłość.
                    </p>
                    <p>
                      Usługodawca&nbsp;i Usługobiorca&nbsp;mogą rozwiązać umowę
                      o&nbsp;świadczenie Usługi Elektronicznej w&nbsp;każdym
                      czasie w&nbsp;drodze porozumienia stron.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>TRYB POSTĘPOWANIa REKLAMACYJNEGO</h3>
                  </div>
                  <div className="text">
                    <p>
                      Reklamacje związane ze świadczeniem Usług Elektronicznych
                      przez Usługodawcę:
                    </p>
                    <p>
                      Reklamacje związane ze świadczeniem Usług Elektronicznych
                      za pośrednictwem Strony Usługobiorca&nbsp;może składać
                      za&nbsp;pośrednictwem poczty elektronicznej na&nbsp;adres:
                      get@itdone.pl
                    </p>
                    <p>
                      w&nbsp;powyższej wiadomości e-mail, należy podać jak
                      najwięcej informacji i okoliczności dotyczących przedmiotu
                      reklamacji, w&nbsp;szczególności rodzaj i&nbsp;datę
                      wystąpienia&nbsp;nieprawidłowości oraz dane kontaktowe.
                      Podane informacje znacznie ułatwią i&nbsp;przyspieszą
                      rozpatrzenie reklamacji przez Usługodawcę.
                    </p>
                    <p>
                      Rozpatrzenie reklamacji przez Usługodawcę następuje
                      niezwłocznie, nie później niż w&nbsp;terminie 14 dni.
                    </p>
                    <p>
                      Odpowiedź Usługodawcy w&nbsp;sprawie reklamacji jest
                      wysyłana&nbsp;na adres e-mail Usługobiorcy podany
                      w&nbsp;zgłoszeniu reklamacyjnym lub w&nbsp;inny podany
                      przez Usługobiorcę sposób.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>WŁASNOŚĆ INTELEKTUALNA</h3>
                  </div>
                  <div className="text">
                    <p>
                      Wszystkie treści zamieszczone na&nbsp;stronie internetowej
                      pod adresem www.itdone.pl korzystają z ochrony
                      prawno&nbsp;autorskiej i&nbsp;są własnością www.itdone.pl
                      Usługobiorca&nbsp;ponosi pełną odpowiedzialność
                      za&nbsp;szkodę wyrządzoną Usługodawcy, będącą następstwem
                      użycia&nbsp;jakiejkolwiek zawartości strony www.itdone.pl,
                      bez zgody Usługodawcy.
                    </p>
                    <p>
                      Jakiekolwiek wykorzystanie przez kogokolwiek, bez wyraźnej
                      pisemnej zgody Usługodawcy, któregokolwiek
                      z&nbsp;elementów&nbsp;składających się na&nbsp;treść oraz
                      zawartość strony www.itdone.pl stanowi naruszenie
                      prawa&nbsp;autorskiego przysługującego&nbsp;Usługodawcy
                      i&nbsp;skutkuje odpowiedzialnością cywilnoprawną oraz
                      karną.
                    </p>
                  </div>
                </article>
                <article>
                  <div className="title">
                    <h3>POSTANOWIENIa&nbsp;KOŃCOWE</h3>
                  </div>
                  <div className="text">
                    <p>
                      Umowy zawierane za pośrednictwem Strony zawierane są
                      zgodnie z&nbsp;prawem polskim.
                    </p>
                    <p>
                      w&nbsp;przypadku niezgodności jakiejkolwiek części
                      Regulaminu z&nbsp;obowiązującym prawem, w&nbsp;miejsce
                      zakwestionowanego&nbsp;przepisu Regulaminu zastosowanie
                      mają właściwe przepisy prawa&nbsp;polskiego.
                    </p>
                  </div>
                </article>
              </section>
            </main>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default Statute;
