import "./index.scss";

import { Landing, MVP, Policy, Statute, TestView } from "./views/_index";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { Cookie } from "./components/_index";
import { ScrollToTop } from "./hooks/_index";

function App() {
  return (
    <Router>
      <div id="app">
        <Switch>
          {/* <Route path="/test">
            <TestView />
          </Route> */}
          {/* <Route path="/mvp">
            <ScrollToTop />
            <MVP />
          </Route> */}
          <Route path="/regulations/statute">
            <ScrollToTop />
            <Statute />
          </Route>
          <Route path="/regulations/policy">
            <ScrollToTop />
            <Policy />
          </Route>
          <Route exact path="/">
            <ScrollToTop />
            <Landing />
          </Route>
          <Redirect to="/" />
        </Switch>
        <Cookie />
      </div>
    </Router>
  );
}

export default App;
