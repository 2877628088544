import "./Header.scss";

import { Button, MenuItem } from "../_index";
import { useEffect, useState } from "react";

import { HashLink as Link } from "react-router-hash-link";
import Logo from "../Logo/Logo";
import { useScrollListener } from "../../hooks/_index";

const Header = ({ color }) => {
  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();

  const [open, setOpen] = useState(false);
  const closeMobileMenu = () => setOpen(false);
  const handleClick = () => setOpen(!open);

  useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");
    setOpen(false);

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  return (
    <>
      <header id="header" className={`${navClassList.join(" ")} ${color}`}>
        <div className={`section-background ${color}`}>
          <main className="wrapper">
            <div className={open ? "nav__container active" : "nav__container"}>
              <Link to="/#home">
                <Logo
                  color={
                    color === "white" ? "black" : `${open ? "black" : "white"}`
                  }
                />
              </Link>
              <nav>
                <ul className={open ? "menu-options active" : "menu-options"}>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                    }}
                    linkTo="/#home"
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                    }}
                    linkTo="/#services"
                  >
                    Usługi
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                    }}
                    linkTo="/#about"
                  >
                    O nas
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                    }}
                    linkTo="/#work"
                  >
                    Jak pracujemy
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      setOpen(false);
                    }}
                    linkTo="/#portfolio"
                  >
                    Portfolio
                  </MenuItem> */}
                </ul>
                <a className="btn-redirecr" href="#form">
                  <Button
                    onClick={() => {
                      closeMobileMenu();
                    }}
                    className={color === "white" ? "reverse" : ""}
                  >
                    let's get it done
                  </Button>
                </a>
                <div
                  className={`burger-menu ${open ? "open" : null}`}
                  onClick={handleClick}
                >
                  <div className="burger-menu__icon"></div>
                </div>
              </nav>
            </div>
          </main>
        </div>
      </header>
    </>
  );
};

export default Header;
