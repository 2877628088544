import "./ThankUPage.scss";

import { bus } from "../../../img/_index";

const ThankUPage = () => {
  console.warn("regards");

  return (
    <div id="thankUPage">
      <div className="box ">
        <div className="box__content flex column">
          <img src={bus} alt="" />
          <h1 className="small">**IT is on the way</h1>
          <p className="bold">TWOJA WIADOMOŚĆ ZOSTAŁA WYSŁANA</p>
          <span className="vsmall">
            Nasz zespół lada moment zacznie przygotowywać odpowiedź na Twoje
            zapytanie lub skontaktuje się w&nbsp;celu umówienia spotkania. Jeśli
            kontaktujesz się w&nbsp;sprawie trwającego projektu, szybszą
            odpowiedź dostaniesz od naszego Project Managera Kacpra.
          </span>
          <div className="contact">
            <div className="contact__container flex">
              <a className="" href="+48 727 659 360" type="phone">
                +48 727 659 360
              </a>
              <a className="" href="mailto:get@itdone.pl" type="email">
                get@itdone.pl
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankUPage;
